import api from "./index"

/**
 * Get getSettings
 *
 * @return {*}
 */
export const getSettings = () => {
  return api.request(`admin/settings`).get()
}
 
/**
 * Patch Min User Karma to Upload Without Approval
 *
 * @return {*}
 */
export const updateMinKarma = params => {
  return api
    .request(`admin/settings/min_user_karma_to_upload_without_approval`)
    .withParams(params)
    .patch()
}

/**
 * Patch Cooldown Time
 *
 * @return {*}
 */
export const updateCooldownTime = params => {
  return api
    .request(`admin/settings/cooldown_for_new_accounts_to_upload`)
    .withParams(params)
    .patch()
}

/**
 * Patch increase karma post
 *
 * @return {*}
 */
export const updateIncreaseKarmaPostUpvote = params => {
  return api
    .request(`admin/settings/increase_karma_per_action_post_upvote`)
    .withParams(params)
    .patch()
}

/**
 * Patch decrease karma post
 *
 * @return {*}
 */
export const updateDecreaseKarmaPostDownvote = params => {
  return api
    .request(`admin/settings/decrease_karma_per_action_post_downvote`)
    .withParams(params)
    .patch()
}

/**
 * Patch increase karma comment
 *
 * @return {*}
 */
export const updateIncreaseKarmaCommentUpvote = params => {
  return api
    .request(`admin/settings/increase_karma_per_action_comment_upvote`)
    .withParams(params)
    .patch()
}

/**
 * Patch increase karma comment
 *
 * @return {*}
 */
export const updateDecreaseKarmaCommentDownvote = params => {
  return api
    .request(`admin/settings/decrease_karma_per_action_comment_downvote`)
    .withParams(params)
    .patch()
}
