<template>
  <div>
    <v-card v-if="isLoading" class="pa-3">
      <v-progress-linear
        color="white"
        indeterminate
        rounded
        height="3"
      />
    </v-card>
 
    <v-flex v-else xs12 sm12 md12 lg7 xl5 class="mt-3">
      <v-layout row>
        <v-flex xs12 sm8 lg6 class="pb-0">
          <div class="subheading text--darken-1 mb-2">
            Cooldown for new accouts to upload (minutes)
          </div>

          <v-text-field
            ref="cooldown"
            v-model="settings.cooldown_for_new_accounts_to_upload"
            :mask="mask"
            :error-messages="inputCooldownError"
            :rules="numberRules"
            :return-masked-value="true"
            solo
            @input="setCooldown"
            @focusout="inputCooldownError = ''"
          />
        </v-flex>
      </v-layout>

      <v-layout row>
        <v-flex xs12 sm8 lg6 class="pb-0">
          <div class="subheading text--darken-1 mb-2">
            Min user karma to upload without approval
          </div>

          <v-text-field
            v-model="settings.min_user_karma_to_upload_without_approval"
            :mask="mask"
            :error-messages="inputMinKarmaError"
            :rules="numberRules"
            :return-masked-value="true"
            solo
            @input="setMinKarma"
            @focusout="inputMinKarmaError = ''"
          />
        </v-flex>
      </v-layout>

      <v-layout row wrap justify-space-between>
        <v-flex xs12 sm6 md5>
          <div class="subheading text--darken-1 mb-4">
            Increase karma per action
          </div>
          <div class="d-flex wrap justify-space-between">
            <span class="label-text">Post upvote</span>
            <div class="sm-input-wrap">
              <v-text-field
                v-model="settings.increase_karma_per_action_post_upvote"
                :mask="mask"
                :error-messages="inputPostUpvoteError"
                :rules="numberRules"
                :return-masked-value="true"
                solo
                @input="setPostUpvote"
                @focusout="inputPostUpvoteError = ''"
              />
            </div>
          </div>
          <div class="d-flex wrap justify-space-between">
            <span class="label-text">Comment upvote</span>
            <div class="sm-input-wrap">
              <v-text-field
                v-model="settings.increase_karma_per_action_comment_upvote"
                :mask="mask"
                :error-messages="inputCommentUpvoteError"
                :rules="numberRules"
                :return-masked-value="true"
                solo
                @input="setCommentUpvote"
                @focusout="inputCommentUpvoteError = ''"
              />
            </div>
          </div>
        </v-flex>

        <v-flex xs12 sm6 md5>
          <div class="subheading text--darken-1 mb-4">
            Decrease karma per action
          </div>
          <div class="d-flex wrap justify-space-between">
            <span class="label-text">Post downvote</span>
            <div class="sm-input-wrap">
              <v-text-field
                v-model="settings.decrease_karma_per_action_post_downvote"
                :mask="mask"
                :error-messages="inputPostDownvoteError"
                :rules="numberRules"
                :return-masked-value="true"
                solo
                @input="setPostDownvote"
                @focusout="inputPostDownvoteError = ''"
              />
            </div>
          </div>

          <div class="d-flex wrap justify-space-between">
            <span class="label-text">Comment downvote</span>
            <div class="sm-input-wrap">
              <v-text-field
                v-model="settings.decrease_karma_per_action_comment_downvote"
                :mask="mask"
                :error-messages="inputCommentDownvoteError"
                :rules="numberRules"
                :return-masked-value="true"
                solo
                @input="setCommentDownvote"
                @focusout="inputCommentDownvoteError = ''"
              />
            </div>
          </div>
        </v-flex>
      </v-layout>
    </v-flex>
  </div>
</template>

<script>
import {
  getSettings,
  updateCooldownTime,
  updateMinKarma,
  updateIncreaseKarmaPostUpvote,
  updateDecreaseKarmaPostDownvote,
  updateIncreaseKarmaCommentUpvote,
  updateDecreaseKarmaCommentDownvote
} from "../../../../api/settins"
export default {
  name: "SettingsPage",
  data() {
    return {
      mask: "##########",
      isLoading: false,
      numberRules: [
        v => !!v || "Field is required",
        v => (v && v.length <= 10) || "Field must be less than 10 characters"
      ],
      settings: {},
      inputCooldownTimer: null,
      inputMinKarmaTimer: null,
      inputPostUpvoteTimer: null,
      inputCommentUpvoteTimer: null,
      inputPostDownvoteTimer: null,
      inputCommentDownvoteTimer: null,
      inputCooldownError: null,
      inputMinKarmaError: null,
      inputPostUpvoteError: null,
      inputCommentUpvoteError: null,
      inputPostDownvoteError: null,
      inputCommentDownvoteError: null
    }
  },
  created() {
    this.fetchSettingsData()
  },
  methods: {
    fetchSettingsData() {
      this.isLoading = true
      getSettings()
        .then(response => {
          // eslint-disable-next-line no-unused-vars
          for (let [key, value] of Object.entries(response.data)) {
            Object.assign(this.settings, value)
          }
        })
        .catch(this.$notificationHandleResponseError)
        .finally(() => (this.isLoading = false))
    },
    setCooldown() {
      this.inputCooldownError = null
      const params = {
        value: +this.settings.cooldown_for_new_accounts_to_upload
      }
      clearTimeout(this.inputCooldownTimer)
      this.inputCooldownTimer = setTimeout(() => {
        updateCooldownTime(params).catch(error => {
          this.inputCooldownError = error.response.data.errors.value[0]
          this.$notificationHandleResponseError(
            error.response.data.errors.value[0]
          )
        })
      }, 2000)
    },
    setMinKarma() {
      this.inputMinKarmaError = null
      const params = {
        value: +this.settings.min_user_karma_to_upload_without_approval
      }
      clearTimeout(this.inputMinKarmaTimer)
      this.inputMinKarmaTimer = setTimeout(() => {
        updateMinKarma(params).catch(error => {
          this.inputMinKarmaError = error.response.data.errors.value[0]
          this.$notificationHandleResponseError(
            error.response.data.errors.value[0]
          )
        })
      }, 2000)
    },
    setPostUpvote() {
      this.inputPostUpvoteError = null
      const params = {
        value: +this.settings.increase_karma_per_action_post_upvote
      }
      clearTimeout(this.inputPostUpvoteTimer)
      this.inputPostUpvoteTimer = setTimeout(() => {
        updateIncreaseKarmaPostUpvote(params).catch(error => {
          this.inputPostUpvoteError = error.response.data.errors.value[0]
          this.$notificationHandleResponseError(
            error.response.data.errors.value[0]
          )
        })
      }, 2000)
    },
    setCommentUpvote() {
      this.inputCommentUpvoteError = null
      const params = {
        value: +this.settings.increase_karma_per_action_comment_upvote
      }
      clearTimeout(this.inputCommentUpvoteTimer)
      this.inputCommentUpvoteTimer = setTimeout(() => {
        updateIncreaseKarmaCommentUpvote(params).catch(error => {
          this.inputCommentUpvoteError = error.response.data.errors.value[0]
          this.$notificationHandleResponseError(
            error.response.data.errors.value[0]
          )
        })
      }, 2000)
    },
    setPostDownvote() {
      this.inputPostDownvoteError = null
      const params = {
        value: +this.settings.decrease_karma_per_action_post_downvote
      }
      clearTimeout(this.inputPostDownvoteTimer)
      this.inputPostDownvoteTimer = setTimeout(() => {
        updateDecreaseKarmaPostDownvote(params).catch(error => {
          this.inputPostDownvoteError = error.response.data.errors.value[0]
          this.$notificationHandleResponseError(
            error.response.data.errors.value[0]
          )
        })
      }, 2000)
    },
    setCommentDownvote() {
      this.inputCommentDownvoteError = null
      const params = {
        value: +this.settings.decrease_karma_per_action_comment_downvote
      }
      clearTimeout(this.inputCommentDownvoteTimer)
      this.inputCommentDownvoteTimer = setTimeout(() => {
        updateDecreaseKarmaCommentDownvote(params).catch(error => {
          this.inputCommentDownvoteError = error.response.data.errors.value[0]
          this.$notificationHandleResponseError(
            error.response.data.errors.value[0]
          )
        })
      }, 2000)
    }
  }
}
</script>

<style scoped>
.label-text {
  line-height: 48px;
}
.sm-input-wrap {
  max-width: 124px;
  flex: 0 0 124px;
}
</style>
